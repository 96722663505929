import {
    completeReducer,
    failureReducer,
    mappedReducer,
    pendingReducer,
    typeComplete,
    typeFail,
    typePending,
    passReducer,
} from 'state-domains/utils';

import { LOAD_STATUS_PENDING } from '../../../constants';
import { BaseAction, ErrorAction } from '../../../types';
import {
    LIST_MESSAGES,
    LOGOUT_REQUEST,
    DELETE_MESSAGE,
    BULK_DELETE_MESSAGES,
    POLL_MESSAGES,
    UPDATE_MESSAGE,
    BULK_READ_MESSAGES,
    BULK_UNREAD_MESSAGES,
    CLEAR_MESSAGES,
    GET_TOKEN,
} from '../../../types/actionTypes';
import {
    NotificationsState,
    Message,
    MessagesPayload,
    MessageMap,
    TrayState,
    MessageState,
    UidKeysPayload,
    PollMessagesPayload,
    UpdateMessagesPayload,
    TokenPayload,
    DeleteNotificationsFailurePayload,
    UpdateNotificationsFailurePayload,
} from '../types';

import {
    reducer as pReducer,
    RESOURCE_STATE,
    FILTER_STATE,
    DIGEST_STATE,
} from './preferences/reducer';

export const MESSAGE_STATE: MessageState = pendingReducer({
    total: 0, // all total
    itemIds: [],
    map: {},
    next: null,
    previous: null,
    seen: null,
});

export const TRAY_STATE: TrayState = pendingReducer({
    total: 0, // new total
    itemIds: [],
    seen: '',
});

export const INITIAL_STATE: NotificationsState = {
    trayState: TRAY_STATE,
    messageState: MESSAGE_STATE,
    resourceState: RESOURCE_STATE,
    filterState: FILTER_STATE,
    digestState: DIGEST_STATE,
    instanceUuid: '',
    instanceDisplayName: '',
    notificationsAuth: {
        token: null,
        error: null,
        status: LOAD_STATUS_PENDING,
    },
};

const loadAllFailed = (state: NotificationsState, action: ErrorAction<object>): NotificationsState => {
    const {
        payload: { error },
    } = action;
    const { messageState } = state;

    const nextMessageState = failureReducer(messageState);
    nextMessageState.error = error;

    return {
        ...state,
        messageState: nextMessageState,
    };
};

function pendingMessageList(state: NotificationsState, _action: BaseAction): NotificationsState {
    return { ...state, messageState: pendingReducer(state.messageState) };
}

function updateMessageMap(map: MessageMap, obj: Message): MessageMap {
     
    map[obj.uuid] = completeReducer({ ...map[obj.uuid], ...obj });
    return map;
}

const loadAllComplete = (
    state: NotificationsState,
    action: BaseAction<MessagesPayload>,
): NotificationsState => {
    const {
        payload: { total, messages, next, prev },
    } = action;
    const orderedIds = messages.map((msg) => msg.uuid);
    const completeMessages = messages.reduce(updateMessageMap, { ...state.messageState.map });
    const messageState = completeReducer({
        total,
        next,
        prev,
        map: completeMessages,
        itemIds: orderedIds,
    });
    return { ...state, messageState };
};

function pollMessagesComplete(
    state: NotificationsState,
    action: BaseAction<PollMessagesPayload>,
): NotificationsState {
    const {
        payload: { total, messages, seen },
    } = action;

    const itemIds = messages.map((msg) => msg.uuid);

    const trayState = completeReducer({ total, seen, itemIds });
    const completeMessages = messages.reduce(updateMessageMap, { ...state.messageState.map });

    return {
        ...state,
        trayState,
        messageState: { ...state.messageState, map: completeMessages },
    };
}

function pendingUpdateMessages(
    state: NotificationsState,
    action: BaseAction<UpdateMessagesPayload>,
): NotificationsState {
    const {
        payload: { uids },
    } = action;
    const {
        messageState: { map },
    } = state;
    const updatedMessagesMap: MessageMap = { ...map };
    uids.forEach((uid: string) => {
        updatedMessagesMap[uid] = pendingReducer(updatedMessagesMap[uid]);
    });
    return {
        ...state,
        messageState: { ...state.messageState, map: updatedMessagesMap },
    };
}

function completeUpdateMessages(
    state: NotificationsState,
    action: BaseAction<UpdateMessagesPayload>,
): NotificationsState {
    const {
        payload: { uids, read },
    } = action;
    const {
        messageState: { map },
    } = state;
    const updatedMessagesMap: MessageMap = { ...map };
    uids.forEach((uid: string) => {
        updatedMessagesMap[uid] = completeReducer({
            ...updatedMessagesMap[uid],
            read,
        });
    });
    return { ...state, messageState: { ...state.messageState, map: updatedMessagesMap } };
}

function failUpdateMessages(
    state: NotificationsState,
    action: BaseAction<UpdateNotificationsFailurePayload>,
): NotificationsState {
    const {
        payload: { error, notificationsIdList },
    } = action;
    const {
        messageState: { map },
    } = state;
    const updatedMessagesMap: MessageMap = { ...map };
    notificationsIdList.forEach((uid: string) => {
        updatedMessagesMap[uid] = failureReducer({
            ...updatedMessagesMap[uid],
            error,
        });
    });
    return {
        ...state,
        messageState: { ...state.messageState, map: updatedMessagesMap },
    };
}

function pendingDeleteMessages(
    state: NotificationsState,
    action: BaseAction<UidKeysPayload>,
): NotificationsState {
    const {
        payload: { uids: deletingUids },
    } = action;
    const {
        messageState: { map },
    } = state;

    const updatedMessagesMap: MessageMap = { ...map };
    deletingUids.forEach((uid: string) => {
        updatedMessagesMap[uid] = pendingReducer(updatedMessagesMap[uid]);
    });

    const messageState = pendingReducer({ ...state.messageState, map: updatedMessagesMap });
    return { ...state, messageState };
}

function completeDeleteMessages(
    state: NotificationsState,
    action: BaseAction<UidKeysPayload>,
): NotificationsState {
    const {
        payload: { uids: deletedUids = [] },
    } = action;
    const {
        messageState: { map, itemIds: pageItemIds },
        trayState: { itemIds: trayItemIds },
    } = state;

    const updatedMessagesMap: MessageMap = { ...map };
    deletedUids.forEach((uid: string) => {
        delete updatedMessagesMap[uid];
    });

    const deletedIdsFilteringPredicate = (id: string) => !deletedUids.includes(id);
    const newPageItemIds = pageItemIds.filter(deletedIdsFilteringPredicate);
    const newTrayItemIds = trayItemIds.filter(deletedIdsFilteringPredicate);

    const totalDeleted = deletedUids.length;

    const trayState = {
        ...state.trayState,
        itemIds: newTrayItemIds,
        total: state.trayState.total - totalDeleted,
    };
    const messageState = completeReducer({
        ...state.messageState,
        map: updatedMessagesMap,
        itemIds: newPageItemIds,
        total: state.messageState.total - totalDeleted,
    });
    return { ...state, trayState, messageState };
}

function failDeleteMessages(
    state: NotificationsState,
    action: BaseAction<DeleteNotificationsFailurePayload>,
): NotificationsState {
    const {
        payload: { error, notificationsIdList },
    } = action;
    const {
        messageState: { map },
    } = state;

    const updatedMessagesMap: MessageMap = { ...map };
    notificationsIdList.forEach((uid: string) => {
        updatedMessagesMap[uid] = failureReducer({
            ...updatedMessagesMap[uid],
            error,
        });
    });

    const messageState = { ...state.messageState, map: updatedMessagesMap };
    return { ...state, messageState };
}

function clearMessages(
    state: NotificationsState,
    action: BaseAction<UidKeysPayload>,
): NotificationsState {
    const {
        payload: { uids: clearedUids },
    } = action;
    const {
        trayState: { itemIds },
    } = state;
    const newItemIds = itemIds.filter((uid) => !clearedUids.includes(uid));
    return { ...state, trayState: { ...state.trayState, itemIds: newItemIds } };
}

function setTokenPending(state: NotificationsState, _action: BaseAction<TokenPayload>) {
    return { ...state, notificationsAuth: pendingReducer({ ...state.notificationsAuth }) };
}

function setTokenComplete(state: NotificationsState, action: BaseAction<TokenPayload>) {
    const { token } = action.payload;
    const newState = { ...state.notificationsAuth, token, error: null };
    return { ...state, notificationsAuth: completeReducer(newState) };
}

function setTokenFailed(state: NotificationsState, action: BaseAction<TokenPayload>) {
    const { error = null } = action.payload;
    const newState = { ...state.notificationsAuth, error };
    return { ...state, notificationsAuth: failureReducer(newState) };
}

export const reducer = mappedReducer(INITIAL_STATE, {
    [CLEAR_MESSAGES]: clearMessages,

    [typePending(LIST_MESSAGES)]: pendingMessageList,
    [typeComplete(LIST_MESSAGES)]: loadAllComplete,
    [typeFail(LIST_MESSAGES)]: loadAllFailed,

    [typePending(POLL_MESSAGES)]: passReducer,
    [typeComplete(POLL_MESSAGES)]: pollMessagesComplete,
    [typeFail(POLL_MESSAGES)]: passReducer,

    [typePending(DELETE_MESSAGE)]: pendingDeleteMessages,
    [typeComplete(DELETE_MESSAGE)]: completeDeleteMessages,
    [typeFail(DELETE_MESSAGE)]: failDeleteMessages,

    [typePending(BULK_DELETE_MESSAGES)]: pendingDeleteMessages,
    [typeComplete(BULK_DELETE_MESSAGES)]: completeDeleteMessages,
    [typeFail(BULK_DELETE_MESSAGES)]: failDeleteMessages,

    [typePending(UPDATE_MESSAGE)]: pendingUpdateMessages,
    [typeComplete(UPDATE_MESSAGE)]: completeUpdateMessages,
    [typeFail(UPDATE_MESSAGE)]: failUpdateMessages,

    [typePending(BULK_READ_MESSAGES)]: pendingUpdateMessages,
    [typeComplete(BULK_READ_MESSAGES)]: completeUpdateMessages,
    [typeFail(BULK_READ_MESSAGES)]: failUpdateMessages,

    [typePending(BULK_UNREAD_MESSAGES)]: pendingUpdateMessages,
    [typeComplete(BULK_UNREAD_MESSAGES)]: completeUpdateMessages,
    [typeFail(BULK_UNREAD_MESSAGES)]: failUpdateMessages,

    [typePending(GET_TOKEN)]: setTokenPending,
    [typeComplete(GET_TOKEN)]: setTokenComplete,
    [typeFail(GET_TOKEN)]: setTokenFailed,

    ...pReducer,
    [typeComplete(LOGOUT_REQUEST)]: () => INITIAL_STATE,
});
